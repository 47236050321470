import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "q-gutter-y-md q-pa-md text-center" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "text-bold"
}
const _hoisted_4 = {
  key: 1,
  class: "text-bold"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "text-bold" }
const _hoisted_7 = { class: "flex justify-end q-gutter-x-sm" }
const _hoisted_8 = {
  key: 1,
  class: "flex justify-center items-center q-py-lg"
}

import QSpinner from 'quasar/src/components/spinner/QSpinner.js';import useQuasar from 'quasar/src/composables/use-quasar.js';;
import useDialogPluginComponent from 'quasar/src/composables/use-dialog-plugin-component.js';;
import { computed, onMounted, toRefs } from "vue";

import { TargetType } from "@/api/target/typedefs";
import TargetingPicker from "@/components/targetingpicker/TargetingPicker.vue";
import { isNodeObject } from "@/components/targetingpicker/utils/guards";
import {
  TargetingDataErrorResponse,
  useTargetingData,
} from "@/composables/useTargetingData";


export default /*@__PURE__*/_defineComponent({
  __name: 'MessageActivateDialog',
  props: {
    productLineId: {},
    targetType: {},
    targetId: {},
    scheduleTime: {}
  },
  setup(__props: any) {

const props = __props;

const affectedUsers = computed(() => {
  return targetValue.value?.affectedUsers?.[0]?.[0];
});

const affectedUsersDate = computed(() => {
  return targetValue.value?.affectedUsers?.[0]?.[1];
});

const { productLineId, targetType, targetId } = toRefs(props);

const $q = useQuasar();

const { isLoading, targetValue, fetchData } = useTargetingData({
  productLineId: productLineId,
  targetType: targetType,
  targetId: targetId,
  onError: (payload: TargetingDataErrorResponse) => {
    $q.notify({
      type: "negative",
      message: payload.message,
    });
  },
});

const { dialogRef, onDialogOK, onDialogCancel } = useDialogPluginComponent();

onMounted(fetchData);

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    ref_key: "dialogRef",
    ref: dialogRef
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, { class: "dialog-card q-dialog-plugin" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (!_unref(isLoading))
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", null, [
                    (_ctx.scheduleTime)
                      ? (_openBlock(), _createElementBlock("h5", _hoisted_3, " You are scheduling message delivery at " + _toDisplayString(_ctx.scheduleTime) + " to following users: ", 1))
                      : (_openBlock(), _createElementBlock("h5", _hoisted_4, " You are about to send message now to following users: "))
                  ]),
                  _createElementVNode("div", null, [
                    (_unref(isNodeObject)(_unref(targetValue).segments))
                      ? (_openBlock(), _createBlock(TargetingPicker, {
                          key: 0,
                          "model-value": _unref(targetValue).segments,
                          "product-line-id": props.productLineId,
                          "product-id": -1,
                          type: props.targetType,
                          dictionary: _unref(targetValue).dictionary,
                          "read-only": true
                        }, null, 8, ["model-value", "product-line-id", "type", "dictionary"]))
                      : _createCommentVNode("", true)
                  ]),
                  (affectedUsers.value && affectedUsersDate.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createElementVNode("h6", _hoisted_6, " Number of users: " + _toDisplayString(affectedUsers.value) + ", estimated at " + _toDisplayString(affectedUsersDate.value) + ". ", 1)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_q_btn, {
                      label: "Cancel",
                      color: "secondary",
                      onClick: _unref(onDialogCancel)
                    }, null, 8, ["onClick"]),
                    _createVNode(_component_q_btn, {
                      label: "Submit",
                      color: "primary",
                      onClick: _unref(onDialogOK)
                    }, null, 8, ["onClick"])
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_unref(QSpinner), {
                    "data-test": "ia.loading",
                    size: "xl",
                    color: "primary"
                  })
                ]))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 512))
}
}

})