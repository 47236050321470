import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "message q-pa-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_message_header = _resolveComponent("message-header")!
  const _component_message_form = _resolveComponent("message-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_message_header, { "product-line": _ctx.productLine }, null, 8, ["product-line"]),
    _createVNode(_component_message_form, {
      "push-message": _ctx.pushMessage,
      "product-line": _ctx.productLine,
      "is-loading": _ctx.isLoading,
      "is-editing-active-push-message": _ctx.isEditingActivePushMessage,
      "is-read-only": _ctx.isReadOnly,
      "is-clone": _ctx.isClone,
      "has-user-test-device": _ctx.hasUserTestDevices,
      "has-unsaved-changes": _ctx.hasUnsavedChanges,
      "validation-status": _ctx.validationStatus,
      "is-add-image-button-visible": _ctx.hasAddImagePermission,
      "has-dry-run-permissions": _ctx.hasDryRunPermissions,
      "allowed-action-buttons": _ctx.allowedActionButtons,
      onPushMessageUpdate: _ctx.onPushMessageUpdate,
      onActivate: _ctx.onActivateAction,
      onCancel: _ctx.onCancelAction,
      onClone: _ctx.onCloneAction,
      onDelete: _ctx.onDeleteAction,
      onPreview: _ctx.onPreviewAction,
      onReset: _ctx.onResetAction,
      onSubmit: _ctx.onSubmitAction,
      onSubmitForActiveMessage: _ctx.onSubmitActivePushMessageAction,
      onValidate: _ctx.onValidateAction,
      onEdit: _ctx.onEditActivePushMessageAction,
      onFormValidationError: _ctx.onFormValidationError
    }, null, 8, ["push-message", "product-line", "is-loading", "is-editing-active-push-message", "is-read-only", "is-clone", "has-user-test-device", "has-unsaved-changes", "validation-status", "is-add-image-button-visible", "has-dry-run-permissions", "allowed-action-buttons", "onPushMessageUpdate", "onActivate", "onCancel", "onClone", "onDelete", "onPreview", "onReset", "onSubmit", "onSubmitForActiveMessage", "onValidate", "onEdit", "onFormValidationError"])
  ]))
}